import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Layout, Shared } from '../../components';

const ThanksRequestPage = () => {
  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <section className="bg-primary text-white mt-n4 py-5">
        <Container fluid="lg" className="content">
          <Row>
            <Col className="text-start">
              <h1>Thanks for Requesting Your Free Property Tax Analysis</h1>
              <p>We’ll be in touch shortly with news on your property’s value. In the meantime, watch below about how property tax protest works.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <Shared.TaxAppealsMadeEasy />
      <Layout.Footer />
    </>
  );
};

export default ThanksRequestPage;

export const Head = () => <Layout.PageHead subtitle="Thank You" googleNoIndex />;
